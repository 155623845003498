/**
 * Created by gschulz on 29.02.16.
 */
let OrganisationMapSection = (function () {

    let _private = {};

    _private.form = $();

    _private.mapSection = $();

    this.init = function (form) {
        _private.form       = form;
        _private.mapSection = _private.form.find('div.section.map');
        _private.initFormTable();
        _private.initMetaOptions();
        _private.bindRemoveLayer();
        _private.bindTogglerTitle();
        _private.mapSection.on('toggledOpen', () => {
            if (!_private.boundsMap) {
                _private.initBoundsMap();
            }
        });
        return this;
    };

    this.beforeSave = function () {
        _private.mapSection.find('input[type=hidden][name*="[meta]"]').each(function (i, input) {
            input = $(input);
            if ($.trim(input.val()) === '') {
                input.remove();
            }
        });
    };

    _private.initFormTable = function () {
        let formTableClass = new FormTable();
        formTableClass.setReorderMethod(_private.reorderList).init(_private.mapSection.find('div.map-layers'));
        _private.mapSection.find('span.plus').unbind('click.addLayer').on('click.addLayer',
            function () {
                let newLine = _private.mapSection.find('.form-table-line:first').clone();
                _private.reorderList(_private.mapSection.find('.form-table').append(newLine));
                _private.initFormTable();
                newLine.find('input[type=text],input[type=hidden]').val('');
                newLine.find('input[type=checkbox]').prop('checked', false);
                newLine.find('a.toggler').text('Layer Title').addClass('open');
                newLine.find('div.section').show();
                newLine.find('input[name*="[title]"]').focus();
                EfbHelper.initToggler(_private.mapSection);

            }
        );
    };


    _private.reorderList = function (list) {
        $(list).find('.form-table-line').each(function (i, line) {
            $(line).find('input,label').each(function (l, inp) {
                inp = $(inp);
                $.each(['id', 'name', 'for'], function (k, attrName) {
                    let attr = inp.attr(attrName);
                    if (!_.isUndefined(attr)) {
                        inp.attr(attrName, attr.replace(/\d+/, i));
                    }
                });
            });
        });
    };

    _private.initMetaOptions = function () {
        _private.mapSection.find('div.meta-data').find('input[name*="[key][]"],input[name*="[value][]"]')
                .unbind('keyup.applyKey').on('keyup.applyKey',
            function (event) {
                let input  = $(event.target);
                let value  = input.val();
                let target = input.parents('div.meta-data-line').find('input[type=hidden]');
                let type   = input.attr('name').indexOf('[key][]') !== -1 ? 'key' : 'value';
                if (type === 'key') {
                    let targetName = target.attr('name').split('[meta]');
                    target.attr('name', targetName[0] + '[meta][' + value + ']');
                } else {
                    target.val(value);
                }
            }
        );
        _private.mapSection.find('div.meta-data').find('input[name*="[key][]"]')
                .unbind('blur.checkAndRemove').on('blur.checkAndRemove',
            function (event) {
                let input = $(event.target);
                let value = $.trim(input.val());
                if (value !== '') {
                    return;
                }
                if (input.parents('div.meta-data').find('div.meta-data-line').length < 2) {
                    return;
                }
                input.parents('div.meta-data-line').remove();
            }
        );
        _private.mapSection.find('a.add-meta-line').unbind('click.addMetaLine').on('click.addMetaLine',
            function (event) {
                let metaData = $(event.target).parents('.form-table-line').find('div.meta-data');
                let newLine  = metaData.find('div.meta-data-line:first').clone();
                newLine.find('input').val('');
                metaData.append(newLine);
                new FormObserver(_private.form).addElement(newLine);
                newLine.find('input[name*="[key][]"]').focus();
                _private.initMetaOptions();
            }
        );
    };

    _private.bindRemoveLayer = () => {
        _private.mapSection.unbind('click.removeLayer').on('click.removeLayer', '.toggler-wrap a.remove', (e) => {
            let layerSection = $(e.target).parents('.form-table-line');
            new FormObserver(_private.form).removeElements(layerSection, true, true);
            layerSection.remove();
            _private.reorderList(_private.mapSection);
        })
    };

    _private.bindTogglerTitle = () => {
        _private.mapSection.unbind('keyup.updateTitle').on('keyup.updateTitle', 'input[name*="[title]"]', (e) => {
            let layerSection = $(e.target).parents('.form-table-line');
            let title        = $.trim($(e.target).val()) || 'Layer Title';
            layerSection.find('a.toggler').text(title);
        })
    };

    _private.initBoundsMap = () => {
        let bounds = {},
            inputs = {},
            kmb    = {_northEast: 'north_east', _southWest: 'south_west'},
            kml    = {lat: 'lat', lng: 'lon'},
            kmbI   = _.invert(kmb),
            kmlI   = _.invert(kml)
        ;

        let getFormBounds = () => {
            let boundsFD = Object.resolve('map.bounds', _private.form.serializeObject());
            return Object.keys(kmbI).every(kb => {
                bounds[kmbI[kb]] = {};
                return _.isObject(boundsFD[kb]) && Object.keys(kmlI).every(lb => {
                    bounds[kmbI[kb]][kmlI[lb]] = parseFloat(boundsFD[kb][lb]);
                    return !isNaN(bounds[kmbI[kb]][kmlI[lb]]);
                });
            }) ? L.latLngBounds(bounds._northEast, bounds._southWest) : null;
        };

        bounds = getFormBounds();

        _private.boundsMap = L.map('bounds-map', {
            fullscreenControl: true,
            center:            [0, 0],
            minZoom:           0,
            zoom:              2
        });
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '',
            subdomains:  ['a', 'b', 'c']
        }).addTo(_private.boundsMap);
        let editableLayers = new L.FeatureGroup();
        _private.boundsMap.addLayer(editableLayers);

        let drawControl     = new L.Control.Draw({
            position: 'topright',
            draw:     {
                circle:       false,
                circlemarker: false,
                polygon:      false,
                polyline:     false,
                marker:       false,
                rectangle:    {},
            },
            edit:     {
                featureGroup: editableLayers,
                edit:         false,
                remove:       true
            }
        });
        let setLayerOptions = layer => {
            layer.setStyle({
                color:       'magenta',
                weight:      2,
                dashArray:   '10, 4',
                fillColor:   'red',
                fillOpacity: 0.2
            })
        };
        let layerToBounds   = e => {
            let bounds = e.layer._bounds;
            Object.keys(bounds).forEach(bk => {
                let latLng = bounds[bk];
                Object.keys(latLng).forEach(lk => {
                    let name = 'map[bounds][' + kmb[bk] + '][' + kml[lk] + ']';
                    if (!inputs[name]) {
                        inputs[name] = _private.mapSection.find('input[name="' + name + '"]');
                    }
                    inputs[name].val(EfbHelper.round(bounds[bk][lk], 2)).blur();
                });
            });
        };
        _private.boundsMap.on(L.Draw.Event.CREATED, e => {
            editableLayers.clearLayers();
            editableLayers.addLayer(e.layer);
            e.layer.editing.enable();
            setLayerOptions(e.layer);
            layerToBounds(e);
        });
        _private.boundsMap
                .on(L.Draw.Event.EDITED, layerToBounds)
                .on(L.Draw.Event.EDITRESIZE, layerToBounds)
                .on(L.Draw.Event.EDITMOVE, layerToBounds);
        _private.boundsMap.addControl(drawControl);

        if (bounds) {
            let layer = L.rectangle(bounds);
            editableLayers.addLayer(layer);
            layer.editing.enable();
            setLayerOptions(layer);
            _private.boundsMap.fitBounds(bounds);
        }
        let formInputs = _private.mapSection.find('input[name^="map[bounds]"]');
        _private.boundsMap.whenReady(() => {
            drawControl._toolbars.draw.removeToolbar();
            drawControl._toolbars.edit.removeToolbar();
            $(drawControl._container).find('a').removeAttr('href');
            let trash = $(drawControl._container).find('.leaflet-draw-edit-remove');
            trash.off('click').on('click', e => {
                e.preventDefault();
                editableLayers.clearLayers();
                formInputs.val('').blur();
            });
        });
        formInputs.unbind('blur.updateBounds').on('blur.updateBounds', e => {
            if(!e.originalEvent){
                return;
            }
            let bounds = getFormBounds();
            editableLayers.clearLayers();
            if(!bounds){
                return;
            }
            let layer = L.rectangle(bounds);
            editableLayers.addLayer(layer);
            layer.editing.enable();
            setLayerOptions(layer);
            _private.boundsMap.fitBounds(bounds);
        })
    };

});